<template>
  <v-container class="pt-4">
    <div>
      <div v-if="examQuestionRes && !examQuestionRes.exam.isDone">
        <v-row>
          <v-col cols="12" md="12" class="pa-1">
            <v-card class="d-flex align-center rounded-lg pa-2 px-4" flat>
              <h4 class="">
                {{
                  examQuestionRes.exam
                    ? `${examQuestionRes.exam.title} - ${examQuestionRes.exam.subjectName}`
                    : ""
                }}
              </h4>
              <v-spacer></v-spacer>
              <v-btn tile color="primary" @click="back()" class="rounded-xl">
                {{ $t("back") }}
                <v-icon right> mdi-arrow-left </v-icon>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="9" sm="12" class="pa-1">
            <v-card class="align-center rounded-lg pa-2 px-4" flat>
              <ShowQuestion
                v-for="item in visiblePages"
                :Question="item"
                :ExamGuid="id"
                :IsEnd="isEnd"
                v-on:send-result-values="nextQuestion"
                :key="item.componentKey"
                :ShowCorrectAnswerAfterSubmit="
                  examQuestionRes.exam.showCorrectAnswerAfterSubmit
                "
                @on-flag-changed="flagChanged"
              ></ShowQuestion>
              <v-card-actions v-if="isInRole(0)">
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="isEnd || !checkAnswers(currentPage)"
                  outlined
                  color="secondary"
                  class="mx-1"
                  @click="getNextPage()"
                >
                  {{ $t("next") }}
                  <v-icon
                    >{{
                      $vuetify.rtl ? "mdi-chevron-left" : "mdi-chevron-right"
                    }}
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="3" sm="12" class="pa-1">
            <v-card class="align-center rounded-lg py-4 px-0" flat dark>
              <Timer
                :starttime="starttime"
                :endtime="endtime"
                :trans="trans"
                :totalSeconds="examQuestionRes.exam.remainingTime"
                :type="
                  isInRole(0) && examQuestionRes.exam.restrictedByTime
                    ? 'period'
                    : ''
                "
                :nowDate="examQuestionRes.exam.currentServerDatetime"
                v-on:send-result-values="back"
              >
              </Timer>
            </v-card>

            <v-card class="align-center rounded-lg mt-2 pa-6" flat>
              <v-row>
                <v-col
                  cols="3"
                  xl="3"
                  lg="4"
                  md="6"
                  sm="3"
                  xs="3"
                  class="pa-2"
                  v-for="item in examQuestionRes.paginationSettings.pagesCount"
                  :key="item"
                >
                  <v-badge
                    bordered
                    :value="checkFlagStatus(item)"
                    color="error"
                    left
                    icon="mdi-flag"
                    overlap
                    style="width: 100%"
                  >
                    <v-btn
                      block
                      @click="showQuestion(item)"
                      :outlined="currentPage != item"
                      color="primary"
                      :disabled="!checkAnswers(item - 1)"
                      :key="item"
                    >
                      <span style="font-weight: bold">{{ item }}</span>
                    </v-btn>
                  </v-badge>

                  <v-progress-linear
                    :key="item"
                    v-if="isInRole(0)"
                    max="3"
                    class="mt-1"
                    height="5"
                    :value="getPercentage(item)"
                    color="primary"
                    background-color="pink lighten-3"
                  >
                  </v-progress-linear>
                </v-col>

              </v-row>
              <v-row v-if="isInRole(0)" class="mt-10"
                ><v-col cols="12" class="pa-1">
                  <v-btn
                    width="100%"
                    @click="doneTheExam"
                    outlined
                    dark
                    color="red"
                  >
                    {{ $t("exam.FinishTheExam") }}
                  </v-btn>
                </v-col></v-row
              >
            </v-card>

            <v-card
              flat
              v-if="examQuestionRes.exam.description"
              class="align-center rounded-lg mt-2"
            >
              <v-card-text dir="auto" style="white-space: pre-line !important">
                {{ examQuestionRes.exam.description.trim() }}
              </v-card-text>
            </v-card>
            <v-card
              flat
              class="align-center rounded-lg mt-2"
              v-if="isInRole(0)"
            >
              <v-card-text style="white-space: pre-line !important">
                <v-data-table
                  :headers="headers"
                  :items="getQuestionsCount"
                  item-key="questionType"
                  disable-sort
                  hide-default-footer
                ></v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col class="text-center pt-10">
            <img src="../../assets/document.svg" width="200px" />
            <h3>{{ $t("exam.TheExamHasNotStartedYet") }}</h3>
          </v-col>
        </v-row> -->
      </div>
      <div v-else-if="examQuestionRes">
        <v-row>
          <v-col class="text-center pt-10">
            <img src="../../assets/complete.png" />
            <h3>{{ $t("exam.AnswerSubmitted") }}</h3>
            <v-btn class="mt-5" dark depressed to="/"> {{ $t("back") }} </v-btn>
          </v-col>
        </v-row>
      </div>
      <div v-else-if="!examQuestionRes && this.examFinished">
        <v-row>
          <v-col class="text-center pt-10">
            <img width="300" src="../../assets/noData.svg" />
            <h3>{{ $t("exam.ThePeriodHasExpired") }}</h3>
            <v-btn class="mt-5" dark depressed to="/"> {{ $t("back") }} </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-dialog
      v-if="isInRole(0)"
      transition="dialog-bottom-transition"
      persistent
      max-width="600"
      v-model="passwordDialog"
    >
      <v-card>
        <v-toolbar color="primary darken-1" dark
          >{{ $t("exam.examPasswordRequired") }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="
              passwordDialog = false;
              $router.go(-1);
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-text-field
            :label="$t('exam.enterExamPassword')"
            :placeholder="$t('exam.enterExamPassword')"
            outlined
            v-model="passwordEnterToExam"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn
            :loading="loading"
            color="primary darken-1"
            @click="CheckExamPassword()"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Timer from "../../components/exams/timer.vue";
import ShowQuestion from "../../components/exams/showQuestion.vue";

export default {
  components: {
    Timer,
    ShowQuestion,
  },
  props: ["id"],
  data() {
    return {
      headers: [
        { text: this.$t("exam.TypeOfQuestion"), value: "questionType" },
        { text: this.$t("exam.QuestionCount"), value: "count" },
        { text: this.$t("exam.AnsweredQuestionsCount"), value: "status" },
      ],
      isEnd: false,
      examFinished: false,
      overlay: false,
      loading: false,
      examQuestionRes: null,
      starttime: new Date(),
      endtime: new Date(),
      currentQuestion: {},
      passwordEnterToExam: null,
      passwordDialog: false,
      currentPage: 1,
      trans: {
        day: this.$t("exam.day"),
        hours: this.$t("exam.hours"),
        minutes: this.$t("exam.minutes"),
        seconds: this.$t("exam.seconds"),
        expired: this.$t("exam.expired"),
        running: this.$t("exam.running"),
        upcoming: this.$t("exam.upcoming"),
        status: {
          expired: this.$t("exam.status.expired"),
          running: this.$t("exam.status.running"),
          upcoming: this.$t("exam.status.upcoming"),
        },
      },
    };
  },
  computed: {
    visiblePages() {
      return this.examQuestionRes.exam.examQuestions.slice(
        (this.currentPage - 1) *
          this.examQuestionRes.paginationSettings.pageSize,
        this.currentPage * this.examQuestionRes.paginationSettings.pageSize
      );
    },
    getQuestionsCount() {
      if (
        this.examQuestionRes.exam &&
        this.examQuestionRes.exam.examQuestions
      ) {
        const fillInTheBlank = this.examQuestionRes.exam.examQuestions.filter(
          (m) => m.questionType == "FillInTheBlanks"
        );
        const number = this.examQuestionRes.exam.examQuestions.filter(
          (m) => m.questionType == "Number"
        );
        const multiChoice = this.examQuestionRes.exam.examQuestions.filter(
          (m) => m.questionType == "MultiChoice"
        );
        const oneChoice = this.examQuestionRes.exam.examQuestions.filter(
          (m) => m.questionType == "OneChoice"
        );
        const attachedFile = this.examQuestionRes.exam.examQuestions.filter(
          (m) => m.questionType == "AttachedFile"
        );

        const counts = [
          {
            questionType: this.$t("exam.FillInTheBlank"),
            count: fillInTheBlank.length,
            status: fillInTheBlank.filter((m) => m.studentAnswers.length > 0)
              .length,
          },
          {
            questionType: this.$t("exam.FillInTheBlankNumericValue"),
            count: number.length,
            status: number.filter((m) => m.studentAnswers.length > 0).length,
          },
          {
            questionType: this.$t("exam.MultipleChoices"),
            count: multiChoice.length,
            status: multiChoice.filter((m) => m.studentAnswers.length > 0)
              .length,
          },
          {
            questionType: this.$t("exam.ChooseOne"),
            count: oneChoice.length,
            status: oneChoice.filter((m) => m.studentAnswers.length > 0).length,
          },
          {
            questionType: this.$t("exam.UploadAFile"),
            count: attachedFile.length,
            status: attachedFile.filter((m) => m.studentAnswers.length > 0)
              .length,
          },
          {
            questionType: this.$t("exam.TheTotalNumberOfQuestions"),
            count: this.examQuestionRes.exam.examQuestions.length,
            status: this.examQuestionRes.exam.examQuestions.filter(
              (m) => m.studentAnswers.length > 0
            ).length,
          },
        ];
        return counts.filter((item) => item.count > 0);
      }
      return [];
    },
  },
  methods: {
    getNextPage() {
      this.isEnd =
        this.currentPage == this.examQuestionRes.paginationSettings.pagesCount;

      if (this.checkAnswers(this.currentPage)) {
        if (this.isEnd) {
          return;
        } else this.currentPage = this.currentPage + 1;
      }

      this.showQuestion(this.currentPage);
    },
    getPercentage(pageIndex) {
      const questions = this.examQuestionRes.exam.examQuestions.slice(
        (pageIndex - 1) * this.examQuestionRes.paginationSettings.pageSize,
        pageIndex * this.examQuestionRes.paginationSettings.pageSize
      );

      const answeredQuestionsInPage = questions.filter(
        (item) => item.studentAnswers.length > 0
      ).length;

      const answeredCount = (answeredQuestionsInPage / questions.length) * 100;
      return answeredCount;
    },
    checkFlagStatus(pageIndex) {
      const questions = this.examQuestionRes.exam.examQuestions.slice(
        (pageIndex - 1) * this.examQuestionRes.paginationSettings.pageSize,
        pageIndex * this.examQuestionRes.paginationSettings.pageSize
      );

      const flags = questions.filter(
        (item) => this.getFlagStatus(this.id, item.questionGuid) == 1
      ).length;
      if (flags > 0) {
        return true;
      }
      return false;
    },
    getFlagStatus(examGuid, questionGuid) {
      const key = `questionFlag_${examGuid}_${questionGuid}`;
      const value = localStorage.getItem(key);
      return parseInt(value);
    },
    checkAnswers(pageIndex) {
      if (this.isInRole(2)) {
        return true;
      }
      if (!this.examQuestionRes.exam.restrictedMovingToNext) {
        return true;
      }
      const questions = this.examQuestionRes.exam.examQuestions.slice(
        (pageIndex - 1) * this.examQuestionRes.paginationSettings.pageSize,
        pageIndex * this.examQuestionRes.paginationSettings.pageSize
      );
      return questions.every((item) => item.studentAnswers.length > 0);
    },
    showQuestion(selectedPage) {
      if (this.checkAnswers(selectedPage - 1)) {
        this.getQuestions();
      }
      this.currentPage = selectedPage;

      this.isEnd =
        selectedPage == this.examQuestionRes.paginationSettings.pagesCount;
    },
    nextQuestion(studentAnswer) {
      const question = this.examQuestionRes.exam.examQuestions.filter(
        (obj) => obj.questionGuid === studentAnswer.questionGuid
      );
      if (question && question.length > 0) {
        question[0].studentAnswers = studentAnswer.answer.map((obj) => ({ answerText: obj }));
      }

      if (this.examQuestionRes.exam.showCorrectAnswerAfterSubmit) return;

      this.getNextPage();
    },
    getQuestions() {
      this.overlay = true;

      let url = `Exams/GetQuestions?ExamGuid=${this.id}`;
      if (this.isInRole(0)) {
        url += `&StudentGuid=${this.getUserInfo().guid}`;
      }

      this.axios
        .get(url)
        .then((res) => {
          if (res.data.data) {
            this.examQuestionRes = res.data.data;
            const questions = this.examQuestionRes.exam.examQuestions.slice(
              (this.currentPage - 1) *
                this.examQuestionRes.paginationSettings.pageSize,
              this.currentPage *
                this.examQuestionRes.paginationSettings.pageSize
            );

            questions.forEach((e) => {
              e.componentKey = e.questionGuid + Math.random();
            });

            // console.log(this.examQuestionRes.exam.examQuestions);
            this.starttime = this.examQuestionRes.exam.startDate;
            this.endtime = this.examQuestionRes.exam.endDate;
            // setTimeout(() => {
            // }, 100);
          } else if (res.data.statusCode == 3) {
            this.passwordDialog = true;
            this.examQuestionRes = null;
          } else if (res.data.statusCode == 5) {
            this.examFinished = true;
            this.examQuestionRes = null;
          }
        })
        .catch(() => {
          this.back();
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    doneTheExam() {
      if (!this.isInRole(0)) {
        return;
      }

      this.$swal
        .fire({
          title: this.$t("AreYouSureAboutTheOperation"),
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
        .then((result) => {
          if (result.value) {
            this.axios
              .put(`Exams/DoneExam?ExamGuid=${this.id}`)
              .then((res) => {
                this.getQuestions();
              })
              .catch(() => {
                this.$swal.fire({
                  title: this.$t("AnErrorOccurredDuringTheProcess"),
                  text: "",
                  icon: "error",
                  confirmButtonText: this.$t("close"),
                });
              })
              .finally(() => {});
          }
        });
    },
    CheckExamPassword() {
      const data = {
        examGuid: this.id,
        password: this.passwordEnterToExam,
      };
      this.loading = true;
      this.axios
        .post(`Exams/InputExamPassword`, data)
        .then((res) => {
          if (res.data.statusCode == 1) {
            this.passwordDialog = false;
            this.getQuestions();
          } else if (res.data.statusCode == 3) {
 this.$swal.fire({
              title: this.$t("exam.IncorrectPassword"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
} else if (res.data.statusCode == 4) {
 this.$swal.fire({
              title: this.$t("exam.alreadyEnteredToTheExam"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
}
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    flagChanged() {
      this.checkFlagStatus(this.currentPage);
      this.$forceUpdate();
    },
    back() {
      this.$router.go(-1);
    },
  },
  created() {
    this.getQuestions();
  },
};
</script>

<style lang="scss" scoped>
.v-btn:not(.v-btn--round).v-size--default {
  min-width: 5px;
}
</style>
